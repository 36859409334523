import { Vue, Component } from "vue-property-decorator";
import { checkMobile } from "@/redesign/helpers";

@Component({
  components: {
    MobileAuthScreen: () =>
      import("./mobile/MobileAuthScreen/MobileAuthScreen.vue"),
    DesktopAuthScreen: () =>
      import("./desktop/DesktopAuthScreen/DesktopAuthScreen.vue"),
  },
})
export default class AuthScreen extends Vue {
  private get isMobile() {
    return checkMobile(this.$vuetify.breakpoint.width);
  }
}

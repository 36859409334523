import Vue, { PluginObject } from "vue";

import BaseImg from "@/components/BaseImg/BaseImg.vue";
import FormField from "@/components/FormField/FormField.vue";
import VuetifyLazyComponent from "@/components/VuetifyLazyComponent/VuetifyLazyComponent.vue";
import FadeIn from "@/components/FadeIn/FadeIn.vue";

const GlobalComponentsPlugin: PluginObject<undefined> = {
  install() {
    const components = {
      BaseImg,
      FormField,
      VuetifyLazyComponent,
      FadeIn,
      Trans: () => import("@/components/Trans/Trans.vue"),
      ConfirmDialog: () =>
        import("@/components/ConfirmDialog/ConfirmDialog.vue"),
      Portal: () => import("portal-vue").then(({ Portal }) => Portal),
      PortalTarget: () =>
        import("portal-vue").then(({ PortalTarget }) => PortalTarget),
    };

    Object.entries(components).forEach(([id, constructor]) => {
      Vue.component(id, constructor);
    });
  },
};

export default GlobalComponentsPlugin;

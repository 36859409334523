import { Mutation, State } from "vuex-class";
import { Watch, Mixins, Component } from "vue-property-decorator";
import { checkOnline } from "@helpers";
import { authModule, profileModule, userModule } from "@store/namespaces";
import { AuthGetters } from "@store/modules/auth/types";

import {
  ProfileActions,
  ProfileGetters,
  ProfileState,
  ProfileMutations,
} from "@store/modules/profile/types";
import { Role } from "@/types/role";
import { RootMutations, RootState } from "@store/types";
import LangMixin from "@/mixins/lang.mixin";
import { getCookie, setCookie } from "@/lib/cookie";

import LayoutSwitch from "@/components/LayoutSwitch/LayoutSwitch.vue";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import { CardType } from "@/types/card";
import { toggleLoader } from "@/lib/app-loader";

@Component({
  components: {
    LayoutSwitch,
  },
})
export default class App extends Mixins(LangMixin) {
  @State("isReady") private readonly isReadyApp!: RootState["isReady"];

  @Mutation("setOnLine")
  private readonly setOnLineMutation!: RootMutations["setOnLine"];
  @Mutation("setReady")
  private readonly setReadyMutation!: RootMutations["setReady"];
  @Mutation("setUtmLabels")
  private readonly setUtmLabelsMutation!: RootMutations["setUtmLabels"];

  @authModule.Getter
  private readonly authenticated!: AuthGetters["authenticated"];
  @profileModule.State("status")
  private readonly profileStatus!: ProfileState["status"];
  @profileModule.Action("fetchProfile")
  private readonly fetchProfileAction!: ProfileActions["fetchProfile"];
  @profileModule.Action("updateProfile")
  private readonly updateProfileAction!: ProfileActions["updateProfile"];
  @profileModule.Action("profileRefreshToken")
  private readonly profileRefreshTokenAction!: ProfileActions["profileRefreshToken"];
  @profileModule.Mutation("setProfileLanguage")
  private readonly setProfileLanguageMutation!: ProfileMutations["setProfileLanguage"];
  @profileModule.Getter("profileAuthority")
  private readonly profileAuthorityGetter!: ProfileGetters["profileAuthority"];
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];
  @profileModule.Getter("profileId")
  private readonly profileIdGetter!: ProfileGetters["profileId"];
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];

  private isReadyWindow = false;

  private get profileLanguageCode() {
    return this.profileLanguageGetter(this.$vuetify.lang.current);
  }

  @Watch("profileStatus")
  @Watch("authenticated")
  @Watch("$route.name")
  @Watch("isReadyApp")
  private onChangeAuthenticated() {
    if (!this.isReadyApp) {
      return;
    }
    // ToDo: Настроить через $route.meta.access

    const { name: routeName = "" } = this.$route;

    if (!routeName || routeName === "404" || routeName === "confirm-email") {
      return;
    }

    const isAuthRoute = ["login", "forgot-password", "sign-up"].includes(
      routeName
    );

    const userRole = this.profileAuthorityGetter;

    if (
      this.authenticated &&
      (isAuthRoute ||
        ["home", "verify", "blocked-account"].includes(routeName)) &&
      this.profileStatus === "active"
    ) {
      const {
        value: { lastVisitCardType },
      } = this.userWebPreferencesGetter({
        key: UserWebPreferencesKey.CARDS_LIST,
      });

      let routeName =
        lastVisitCardType === CardType.POSTPAID
          ? "cards-with-limit"
          : "cards-with-balance";

      switch (userRole) {
        case Role.ROLE_NETWORK:
          routeName = "profile";
          break;

        case Role.ROLE_ACCOUNTANT:
          routeName = "statistic";
          break;

        default:
          break;
      }

      this.$router.push({
        name: routeName,
      });
    } else if (
      !this.authenticated &&
      !isAuthRoute &&
      !this.profileStatus &&
      !["privacy", "home"].includes(routeName)
    ) {
      this.$router.push({ name: "home" });
    } else if (
      !["cash-flow", "profile"].includes(routeName) &&
      userRole === Role.ROLE_NETWORK
    ) {
      this.$router.push({
        name: "profile",
      });
    }
  }

  @Watch("$route.name")
  @Watch("profileStatus")
  @Watch("isReadyApp")
  private onChangeProfileStatus() {
    if (!this.isReadyApp) {
      return;
    }

    const { name: routeName = "" } = this.$route;

    if (!routeName) return;

    if (
      ![
        "verify",
        "home",
        "login",
        "sign-up",
        "forgot-password",
        "blocked-account",
      ].includes(routeName)
    ) {
      if (this.profileStatus === "disabled") {
        this.$router.push({ name: "blocked-account" });
      } else if (this.profileStatus === "not_verified") {
        this.$router.push({ name: "verify" });
      }
    }
  }

  private async initListenOnline() {
    const onChangeOnline = () => {
      this.setOnLineMutation(navigator.onLine);
    };

    const onLine = await checkOnline();

    this.setOnLineMutation(onLine);

    window.addEventListener("offline", onChangeOnline);
    window.addEventListener("online", onChangeOnline);

    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("offline", onChangeOnline);
      window.removeEventListener("online", onChangeOnline);
    });
  }

  private async initListenUser() {
    try {
      if (this.authenticated) {
        await Promise.all([
          this.fetchProfileAction({ isSaveErrorStatus: true }),
          this.fetchUserWebPreferencesAction({
            key: UserWebPreferencesKey.CARDS_LIST,
          }),
        ]);
      }
    } finally {
      this.setReadyMutation(true);

      this.$watch(
        () => {
          return this.profileIdGetter;
        },
        (profileId) => {
          if (!profileId) return;

          // Меняем язык приложения при первой авторизации пользователя
          this.changeLang(this.profileLanguageCode);
        },
        {
          immediate: true,
        }
      );

      this.$watch(
        () => {
          return this.$vuetify.lang.current;
        },
        async (language) => {
          if (!this.authenticated || language === this.profileLanguageCode) {
            return;
          }

          const { updateProfile } = await import("@/api/profile");

          await updateProfile({
            language,
          });

          this.setProfileLanguageMutation(language);
        }
      );
    }
  }

  private initInviteCode() {
    if (typeof this.$route.query.invite === "string") {
      sessionStorage.setItem("invite_code", this.$route.query.invite);
    }

    if (typeof this.$route.query.referral === "string") {
      sessionStorage.setItem(
        "referral_invite_code",
        this.$route.query.referral
      );
    }
  }

  private initUtmLabels() {
    const utmFromQueryEntries = Object.entries(this.$route.query).filter(
      ([key, value]) => key.includes("utm_") && typeof value === "string"
    );
    const utmFromCookie = getCookie("utm_labels");
    const parsedUtmFromStorage = utmFromCookie
      ? JSON.parse(utmFromCookie)
      : null;

    const utmFromQuery = (
      utmFromQueryEntries.length > 0
        ? Object.fromEntries(utmFromQueryEntries)
        : parsedUtmFromStorage
    ) as Record<string, string> | null;

    if (utmFromQuery) {
      this.setUtmLabelsMutation(utmFromQuery);
      setCookie("utm_labels", JSON.stringify(utmFromQuery), {
        maxAge: 24 * 60 * 60,
      });
    }
  }

  private created() {
    this.isReadyWindow = document.readyState === "complete";

    if (!this.isReadyWindow) {
      const onLoadWindow = () => {
        this.isReadyWindow = true;
      };

      window.addEventListener("load", onLoadWindow, { once: true });
    }

    this.$watch(
      () => {
        return this.isReadyApp && this.isReadyWindow;
      },
      (hidden) => {
        window.dispatchEvent(new CustomEvent("app-ready"));
        toggleLoader(!hidden);
      }
    );
  }

  private mounted() {
    this.$router.onReady(() => {
      this.initListenUser();
      this.initListenOnline();
      this.initUtmLabels();
      this.initInviteCode();
    });
  }
}

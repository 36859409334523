import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import locales from "./locales";
import { getUserLang } from "@/lib/userLang";
import { MOBILE_BREAKPOINT, SCROLLBAR_WIDTH } from "@config/base";
import icons from "./icons";

Vue.use(Vuetify);

const userLang = getUserLang();

document.documentElement.lang = userLang;

export default new Vuetify({
  icons: {
    values: icons,
  },
  lang: {
    current: userLang,
    locales,
  },

  breakpoint: {
    thresholds: {
      md: 1280 + SCROLLBAR_WIDTH,
      lg: 1860 + SCROLLBAR_WIDTH,
    },
    mobileBreakpoint: MOBILE_BREAKPOINT,
    scrollBarWidth: SCROLLBAR_WIDTH,
  },
  theme: {
    options: {
      cspNonce: "dQw4w9WgXcQ",
    },
    themes: {
      light: {
        primary: "#4E96FA",
        error: "#FF515F",
        success: "#45DF83",
        warning: "#FFC008",
      },
    },
  },
});

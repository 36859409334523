import { render, staticRenderFns } from "./Transactions.vue?vue&type=template&id=7b84558e&scoped=true"
import script from "./Transactions.ts?vue&type=script&lang=ts&external"
export * from "./Transactions.ts?vue&type=script&lang=ts&external"
import style0 from "./Transactions.scss?vue&type=style&index=0&id=7b84558e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b84558e",
  null
  
)

export default component.exports
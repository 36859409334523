import { Vue, Component } from "vue-property-decorator";

@Component
export default class RedesignLayout extends Vue {
  private beforeMount() {
    document.documentElement.classList.add("redesign-multicards");
  }

  private beforeDestroy() {
    document.documentElement.classList.remove("redesign-multicards");
  }
}

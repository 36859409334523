import { Vue, Component } from "vue-property-decorator";
import { setUserLang } from "@/lib/userLang";
import { availableLangs } from "@config/lang";

@Component
export default class LangMixin extends Vue {
  protected get langs() {
    return availableLangs;
  }

  protected get activeItem() {
    return this.langs.find(({ code }) => code === this.$vuetify.lang.current);
  }

  protected get showedLangs() {
    return this.langs.filter(({ code }) => code !== this.$vuetify.lang.current);
  }

  protected changeLang(code: string): void {
    this.$vuetify.lang.current = code;
    setUserLang(code);
  }
}

import { loadScript, sleep } from "@helpers";
import { PluginObject } from "vue";

const GoogleTagManagerPlugin: PluginObject<{
  googleId: string;
}> = {
  install(_, options) {
    window.addEventListener(
      "app-ready",
      async () => {
        await sleep(500);

        const googleId = options?.googleId;

        if (!googleId) {
          return;
        }
        const currentPath = window.location.pathname.replace(/\/$/, "") + "/";
        const includePages = ["/", "/login/", "/sign-up/"];

        if (!includePages.includes(currentPath)) {
          return;
        }

        window.dataLayer = window.dataLayer || [];

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function gtag(...args: unknown[]) {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", googleId);

        loadScript({
          src: `https://www.googletagmanager.com/gtag/js?id=${googleId}`,
        });
      },
      {
        once: true,
      }
    );
  },
};

export default GoogleTagManagerPlugin;

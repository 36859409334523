import axios, { AxiosError } from "axios";
import app from "@/main";
import store from "@/store";
import { ProfileGetters } from "@store/modules/profile/types";
import { ApiError, ApiErrorResponse } from "@/types/api";
import { Role } from "@/types/role";
import { getUserInfo } from "@/lib/auth";

axios.interceptors.request.use(
  (config) => {
    const { token } = getUserInfo();

    if (config.headers && token && !config.url?.includes("/auth/")) {
      config.headers["X-Auth-Token"] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error: AxiosError<ApiErrorResponse>) => {
    const url = error.response?.config?.url;
    const statusCode = error.response?.status;
    const isAxiosNotify = error.config.isAxiosNotify ?? true;
    const errorName = (error.response?.data?.error || "") as ApiError;

    if (
      url?.includes("auth/password/restore") &&
      errorName === ApiError.INVALID_CAPTCHA
    ) {
      return Promise.reject(false);
    }

    const userHasRole: ProfileGetters["userHasRole"] =
      store.getters["profile/userHasRole"];

    const errorCode = (error.response?.data?.error_code || "") as string;
    const errorMsg = (
      {
        [ApiError.ACCESS_DENIED]: url?.includes("password/update")
          ? app.$vuetify.lang.t("$vuetify.errors.invalid_current_password")
          : app.$vuetify.lang.t("$vuetify.errors.access_denied"),
        [ApiError.USER_ALREADY_EXISTS]: app.$vuetify.lang.t(
          "$vuetify.errors.user_already_exists"
        ),
        [ApiError.INVALID_SECOND_FACTOR]: app.$vuetify.lang.t(
          "$vuetify.errors.invalid_code"
        ),
        [ApiError.TOO_WEAK_PASSWORD]: app.$vuetify.lang.t(
          "$vuetify.errors.password.length"
        ),
        [ApiError.TOO_MANY_POSTPAID_CARD_ISSUED]: app.$vuetify.lang.t(
          "$vuetify.errors.too_many_postpaid_card_issued"
        ),
        [ApiError.TOO_MANY_CARD_ISSUED]: userHasRole(Role.ROLE_MEDIABUYER)
          ? app.$vuetify.lang.t(
              "$vuetify.errors.too_many_card_issued_mediabuyer"
            )
          : app.$vuetify.lang.t(
              "$vuetify.errors.too_many_card_issued_teamlead"
            ),
        [ApiError.INSUFFICIENT_BALANCE]: app.$vuetify.lang.t(
          "$vuetify.errors.insufficient_balance"
        ),
        [ApiError.INTERNAL_ERROR]: app.$vuetify.lang.t(
          "$vuetify.errors.try_later"
        ),
        [ApiError.MERCHANT_ERROR]: app.$vuetify.lang.t(
          "$vuetify.errors.try_later"
        ),
        [ApiError.INTERNAL_ERROR_ADDITIONAL]: app.$vuetify.lang.t(
          "$vuetify.errors.internal_error"
        ),
        [ApiError.INVALID_INVITE]: app.$vuetify.lang.t(
          "$vuetify.errors.invalid_invite"
        ),
        [ApiError.SMS_CONFIRMATION_VERIFY_FAILED]: app.$vuetify.lang.t(
          "$vuetify.errors.sms_confirmation_verify_failed"
        ),
        [ApiError.SMS_VERIFICATION_MAX_ATTEMPTS_ERROR]: app.$vuetify.lang.t(
          "$vuetify.errors.sms_verification_max_attempts_error"
        ),
        [ApiError.BANK_INQUIRY_DISABLED]: app.$vuetify.lang.t(
          "$vuetify.errors.bank_inquiry_disabled"
        ),
        [ApiError.PROMOCODE_NOT_VALID]: app.$vuetify.lang.t(
          "$vuetify.errors.promocode_not_valid"
        ),
        [ApiError.PROMOCODE_ACCESS_BLOCKED]: app.$vuetify.lang.t(
          "$vuetify.errors.promocode_access_blocked"
        ),
      } as Record<ApiError, string>
    )[errorName];

    const notVerifiedAccount = errorName === ApiError.ACCOUNT_NOT_VERIFIED;

    const disabledAccount =
      errorName === ApiError.USER_NOT_ACTIVE ||
      errorName === ApiError.ACCOUNT_DISABLED ||
      errorName === ApiError.ACCOUNT_DISABLED_BY_ADMIN ||
      errorName === ApiError.ACCOUNT_DISABLED_BY_TEAMLEAD;

    if (disabledAccount) {
      store.commit("profile/setStatus", "disabled");
    } else if (notVerifiedAccount) {
      store.commit("profile/setStatus", "not_verified");
    } else {
      store.commit("profile/setStatus", null);
    }

    if (errorMsg && isAxiosNotify) {
      app.$notify({ title: errorMsg, type: "error", duration: 10e3 });
    } else if (
      isAxiosNotify &&
      errorCode &&
      ![
        ApiError.SECOND_FACTOR_REQUIRED,
        ApiError.RECAPTCHA_ATTEMPT_FAILED,
        ApiError.ACCOUNT_EMAIL_NOT_VERIFIED,
        ApiError.ACCOUNT_EMAIL_ALREADY_VERIFIED,
        ApiError.ACCOUNT_EMAIL_VERIFICATION_EXPIRED,
        ApiError.USER_NOT_FOUND,
      ].includes(errorName) &&
      !disabledAccount &&
      !notVerifiedAccount
    ) {
      app.$notify({ title: `[${errorCode}]: ${errorName}`, type: "error" });
    }

    if (
      statusCode === 401 &&
      !url?.includes("/logout") &&
      (!errorName || errorName === ApiError.INVALID_SESSION)
    ) {
      store.dispatch("auth/logOut", { sendLogoutRequest: false });
    }

    return Promise.reject(error);
  }
);

import { render, staticRenderFns } from "./AuthScreen.vue?vue&type=template&id=aef29d5c"
import script from "./AuthScreen.ts?vue&type=script&lang=ts&external"
export * from "./AuthScreen.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
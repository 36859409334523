import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=6f0a297e&scoped=true"
import script from "./Team.ts?vue&type=script&lang=ts&external"
export * from "./Team.ts?vue&type=script&lang=ts&external"
import style0 from "./Team.scss?vue&type=style&index=0&id=6f0a297e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0a297e",
  null
  
)

export default component.exports
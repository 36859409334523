import { Vue, Component } from "vue-property-decorator";
import { checkMobile } from "@/redesign/helpers";

@Component({
  components: {
    DesktopHome: () => import("./desktop/DesktopHome.vue"),
    MobileHome: () => import("./mobile/MobileHome.vue"),
  },
})
export default class Home extends Vue {
  private get isMobile() {
    return checkMobile(this.$vuetify.breakpoint.width);
  }
}
